$('.navbar-toggle[data-toggle="collapse"]').click(function(){
	var fa = $(this).find('.fa');
	var status = fa.attr('class').replace('fa ','');

	if(status == 'fa-bars'){
		fa.removeClass('fa-bars').addClass('fa-close')
	}else{
		fa.removeClass('fa-close').addClass('fa-bars')
	}

	// console.log(status)
});

$('.sidebar .aux').click(function(){
	$('.sidebar').removeClass('open');
});

$('.btn-toggle-sidebar').click(function(){
	$('.sidebar').addClass('open');
});

$('html').on('swipeleft',function(){
	$('.sidebar').removeClass('open');
});

$('html').on('swiperight',function(){
	$('.sidebar').addClass('open');
});
